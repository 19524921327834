import React from 'react';

import { Grid } from '@core';
import { ImageCore, Text } from '@components';

const MentionCard = ({ logoImage, comment }) => (
  <Grid.Container py={32} px={24}>
    <Grid.Row>
      <Grid.Col mb={24} height={80} display="flex" justifyContent="center" alignItems="center">
        <ImageCore src={logoImage.src} alt={logoImage.alt} height={64} />
      </Grid.Col>
      <Grid.Col>
        <Text.Body2 textAlign="left">{comment}</Text.Body2>
      </Grid.Col>
    </Grid.Row>
  </Grid.Container>
);

export default MentionCard;
